<template>
  <div class="custom-wrapper">
    <!-- # RESPONSIVE BOX SETTINGS -->

    <DxResponsiveBox
      :screen-by-width="screenByWidth"
      single-column-screen="xs"
      id="responsive-box"
      width="100%"
    >
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <DxRow :ratio="1" />
      <!--          <DxRow :ratio="1" />-->

      <DxCol :ratio="1" />
      <DxCol :ratio="1" />
      <DxCol :ratio="1" />

      <!-- # FILTERS -->
      <DxItem class="category-list-selector">
        <DxLocation :row="0" :col="0" :colspan="3" screen="xs sm md lg" />
        <template #default>
          <div class="filterContainer">
            <!-- # STORE FILTER -->
            <div class="storeSelectorContainer">
              <!--                          temp-->
              <DxSelectBox
                style="font-family: 'Nunito'"
                :dataSource="getOutletsList.filter((item) => item.id != 0)"
                displayExpr="name"
                :show-clear-button="false"
                :placeholder="$store.state.selectedOutlet.name"
                value-expr="id"
                :onValueChanged="onOutletSelected"
                :disabled="!effortRateDataReady || user.assets.length == 1"
                styling-mode="underlined"
              />
              <!--                     v-model="selectedOutlet"-->
            </div>

            <div class="filtersContainer">
              <div>
                <!-- # CALENDAR FILTER -->
                <!--                  <div class="display-calendar-btn" @click="onShowCalendar">{{ displayStringDate }}</div>-->
                <!--                  <div class="display-calendar-btn" @click="onShowCalendar">{{ $store.state.selectedDate ? (new Date($store.state.selectedDate.slice(0,4), ($store.state.selectedDate.slice(4,6) - 1), $store.state.selectedDate.slice(6,8))).toLocaleString('en-us', { month: 'short' }) + " " + $store.state.selectedDate.slice(0,4) : (new Date($store.state.outletLastSalesDates.salesLastDayClosedMonth.slice(0,4), ($store.state.outletLastSalesDates.salesLastDayClosedMonth.slice(4,6) - 1), $store.state.outletLastSalesDates.salesLastDayClosedMonth.slice(6,8))).toLocaleString('en-us', { month: 'short' }) + " " + $store.state.outletLastSalesDates.salesLastDayClosedMonth.slice(0,4) }}</div>-->
                <div class="display-calendar-btn" @click="onShowCalendar">
                  {{
                    $store.state.selectedDate
                      ? new Date(
                          $store.state.selectedDate.slice(0, 4),
                          $store.state.selectedDate.slice(4, 6) - 1,
                          $store.state.selectedDate.slice(6, 8)
                        ).toLocaleString("en-us", { month: "short" }) +
                        " " +
                        $store.state.selectedDate.slice(0, 4)
                      : new Date(
                          String(
                            $store.state.newOutletLastSalesDates
                              .lastAvailableSalesDate
                          ).slice(0, 4),
                          String(
                            $store.state.newOutletLastSalesDates
                              .lastAvailableSalesDate
                          ).slice(4, 6),
                          String(
                            $store.state.newOutletLastSalesDates
                              .lastAvailableSalesDate
                          ).slice(6, 8)
                        ).toLocaleString("en-us", { month: "short" }) +
                        " " +
                        String(
                          $store.state.newOutletLastSalesDates
                            .lastAvailableSalesDate
                        ).slice(0, 4)
                  }}
                </div>

                <!--                  <div class="display-calendar-btn" @click="onShowCalendar">{{ $store.state.selectedDate ? $store.state.selectedDate : $store.state.outletLastSalesDates.salesLastDayClosedMonth }}</div>-->
                <div
                  :class="[
                    'calendarContainer',
                    calendarHidden ? 'calendarHidden' : '',
                  ]"
                >
                  <div class="closeCalendarBtn" @click="onShowCalendar">x</div>
                  <div class="calendarButton">
                    <div class="calendarButtonTitle"></div>
                    <button
                      v-for="(dateBtn, ix) in [
                        { name: 'month', value: 'year' },
                        { name: 'week', value: 'month' },
                      ]"
                      :key="ix"
                      :class="[
                        dateBtn.name + '-btn',
                        calendarZoomLevel === dateBtn.value ? 'activeDate' : '',
                        dateBtn.value === 'month' ? 'disabled-btn' : '',
                      ]"
                      @click="onCalendarZoomLevelSelection(dateBtn)"
                      :disabled="dateBtn.value === 'month'"
                    >
                      {{ dateBtn.name }}
                    </button>
                  </div>
                  <div class="calendarComponentContainer">
                    <div class="calendarTitle">
                      {{ loc.FILTERS.CALENDARREFTITLE }}
                    </div>
                    <DxCalendar
                      @value-changed="onSelectedDate"
                      :zoom-level="calendarZoomLevel"
                      :max-zoom-level="calendarZoomLevel"
                      :max="maxSelectableDate"
                      :min="minSelectableDate"
                      :value="
                        $store.state.selectedDate
                          ? `${$store.state.selectedDate.slice(
                              0,
                              4
                            )}-${$store.state.selectedDate.slice(4, 6)}-01`
                          : `${(
                              '' +
                              $store.state.newOutletLastSalesDates
                                .lastAvailableSalesDate
                            ).slice(0, 4)}-${(
                              '' +
                              $store.state.newOutletLastSalesDates
                                .lastAvailableSalesDate
                            ).slice(4, 6)}-01`
                      "
                      cell-template="customCalendarCellTemplate"
                    >
                      <!--                        :disabled-dates="disabledDates"-->
                      <!--                        :value="(this.$store.state.selectedDate === null ? this.$store.state.outletLastSalesDates.salesLastDay : this.$store.state.selectedDate)"-->
                      <!--                        v-model="currentValue"-->
                      <!--                        :min="minDateValue"-->
                      <!--                        :max="maxDateValue"-->
                      <!--                        :disabled-dates="disabledDates"-->
                      <!--                        :first-day-of-week="firstDay"-->
                      <!--                        :disabled="disabled"-->
                      <!--                        :cell-template="cellTemplate"-->
                      <!--                        <template #custom="{ data: cell }">-->
                      <!--                          <span :class="getCellCssClass(cell.date)">-->
                      <!--                            {{ cell.text }}-->
                      <!--                          </span>-->
                      <!--                        </template>-->
                      <template
                        #customCalendarCellTemplate="{ data: data, index }"
                      >
                        <CustomCalendarCellTemplate
                          :data="data"
                          :cell-index="index"
                        />
                      </template>
                    </DxCalendar>
                  </div>
                </div>

                <!--Settings-->
                <div class="display-settings-btn" @click="onShowSettings">
                  <i class="dx-icon dx-icon-preferences fa-4x"></i>
                </div>
                <div
                  :class="[
                    'calendarContainer',
                    settingsHidden ? 'calendarHidden' : '',
                  ]"
                >
                  <div class="closeCalendarBtn" @click="onShowSettings">x</div>
                  <div class="calendarButton">
                    <div class="calendarButtonTitle"></div>
                    <button
                      v-for="(dateBtn, ix) in [{ name: 'year', value: 'year' }]"
                      :key="ix"
                      :class="[
                        dateBtn.name + '-btn',
                        calendarZoomLevel === dateBtn.value ? 'activeDate' : '',
                        dateBtn.value === 'month' ? 'disabled-btn' : '',
                      ]"
                      @click="onCalendarZoomLevelSelection(dateBtn)"
                      :disabled="dateBtn.value === 'year'"
                    >
                      {{ dateBtn.name }}
                    </button>
                  </div>
                  <div class="calendarComponentContainer">
                    <div class="calendarTitle">
                      {{ loc.FILTERS.CALENDARCOMPARISONTITLE }}
                    </div>
                    <DxCalendar
                      @value-changed="onSelectedSetting"
                      :zoom-level="settingsZoomLevel"
                      :max-zoom-level="settingsZoomLevel"
                      :min-zoom-level="settingsZoomLevel"
                      :max="settingsMaxSelectableDate"
                      :min="settingsMinSelectableDate"
                      :value="
                        $store.state.selectedSettings
                          ? `${$store.state.selectedSettings.slice(0, 4)}`
                          : `${'' +
                              ((
                                '' +
                                $store.state.newOutletLastSalesDates
                                  .lastAvailableSalesDate
                              ).slice(0, 4) -
                                1)}`
                      "
                    >
                    </DxCalendar>
                  </div>
                </div>
              </div>

              <!-- # PRODUCT CATEGORIES FILTER -->
              <div
                class="categoriesListSelectorContainer"
                style="width: 350px; font-family: 'Nunito'"
              >
                <DxSelectBox
                  style="font-family: 'Nunito'"
                  :dataSource="getCategoriesList"
                  displayExpr="description"
                  :placeholder="loc.FILTERS.PRODCATPLACEHOLDER"
                  value-expr="id"
                  :onValueChanged="onProductCategorySelected"
                  :disabled="
                    !effortRateDataReady || productCategoryFilterDisabledByPage
                  "
                  :show-clear-button="true"
                  styling-mode="underlined"
                  :value="$store.state.selectedCategory"
                />
                <!--                       v-model="selectedCategory"-->
                <!--                    <DxTextBoxButton name="clear" :options="{ }"/>-->
              </div>
            </div>
          </div>
        </template>
      </DxItem>

      <!-- ############## 1st row ############## -->
      <DxItem class="item doughnut">
        <DxLocation :row="1" :col="0" :colspan="3" screen="xs sm md lg" />
        <template #default>
          <basic-double-doughnut-visualizer
            :componentTitle="loc.EFFORTRATE.BARGAUGETITLE"
            :comparisonDate="comparisonDateLTM"
            :refDate="refDateLTM"
            :dataReady="effortRateDataReady"
            :legend="[
              `ER Good <= ${barGaugeER.legend.goodNormalThreshold}%`,
              `${barGaugeER.legend.goodNormalThreshold}% < ER Normal < ${barGaugeER.legend.normalBadThreshold}%`,
              `ER Bad >= ${barGaugeER.legend.normalBadThreshold}%`,
            ]"
          >
            <template v-slot:componentDoughnut1>
              <DxBarGauge
                @drawn="onDrawn"
                class="gauge"
                :start-value="0"
                :end-value="100"
                :values="barGaugeER.dataRef"
                :geometry="barGaugeGeometry"
                :palette="[colorGood, colorNormal, colorBad]"
                resolveLabelOverlapping="hide"
                :label="barGaugesLabelsSettings"
              >
                <!--                    <DxLabel-->
                <!--                        :indent="30"-->
                <!--                        :format="format"-->
                <!--                        :customize-text="customizeText"-->
                <!--                        :font="barGaugeLabelFont"-->
                <!--                    />-->
                <DxLegend
                  marker-template="customBarGaugeLegendTemplate"
                  :visible="true"
                  verticalAlignment="bottom"
                  horizontalAlignment="center"
                  :customizeText="customizeBarGaugeLegendText"
                  item-text-position="right"
                  :font="getLegendFontSettings"
                />
                <template #customBarGaugeLegendTemplate="{ data }">
                  <CustomBarGaugeLegendTemplate :item="data" />
                </template>
              </DxBarGauge>
            </template>

            <template v-slot:componentDoughnut2>
              <DxBarGauge
                @drawn="onDrawn"
                class="gauge"
                :start-value="0"
                :end-value="100"
                :values="barGaugeER.dataComparison"
                :geometry="barGaugeGeometry"
                :palette="[colorGood, colorNormal, colorBad]"
                resolveLabelOverlapping="hide"
                :label="barGaugesLabelsSettings"
              >
                <!--                    <DxLabel-->
                <!--                        :indent="30"-->
                <!--                        :format="format"-->
                <!--                        :customize-text="customizeText"-->
                <!--                        :font="barGaugeLabelFont"-->
                <!--                    />-->
                <DxLegend
                  marker-template="customBarGaugeLegendTemplate"
                  :visible="true"
                  verticalAlignment="bottom"
                  horizontalAlignment="center"
                  :customizeText="customizeBarGaugeLegendText"
                  item-text-position="right"
                  :font="getLegendFontSettings"
                />
                <template #customBarGaugeLegendTemplate="{ data }">
                  <CustomBarGaugeLegendTemplate :item="data" />
                </template>
              </DxBarGauge>
            </template>
          </basic-double-doughnut-visualizer>
        </template>
      </DxItem>

      <!-- ############## 2nd row ############## -->
      <DxItem>
        <DxLocation :row="2" :col="0" :colspan="3" screen="xs sm md lg" />
        <template #default>
          <div style="position: relative;">
            <DxButton
              :style="
                `display: ${dataDisplayMode == 'chart' ? 'block' : 'none'}`
              "
              id="reset-zoom"
              class="reset-zoom-btn"
              :on-click="resetScatterErZoom"
              :text="loc.COMMON.RESETZOOMTEXT"
            />

            <basic-chart-datagrid-visualizer
              :componentTitle="loc.EFFORTRATE.SCATTERDATAGRIDTITLE"
              :refDate="refDateLTM"
              :dataReady="effortRateDataReady"
              :switch-table-btn="loc.EFFORTRATE.CHARTDATAGRIDSWITCHTABLE"
              :switch-chart-btn="loc.EFFORTRATE.CHARTDATAGRIDSWITCHCHART"
              @changeDisplayDataChild="changeDisplayData($event)"
            >
              <!-- ## SCATTER PLOT -->
              <template v-slot:componentChart>
                <DxChart
                  @drawn="onDrawn"
                  id="scatterChart-filtered-by-client"
                  :data-source="
                    scatterDatagridERLTM.dataFilteredByProductCategory.filter(
                      (el) => el.sales != null && el.er != null
                    )
                  "
                  class="scatterChart"
                  :commonAxisSettings="scatterChartCommonAxisSettings"
                  :loadingIndicator="{ enabled: true }"
                  @initialized="saveScatterErInstance"
                >
                  <!--                             @point-click="onPointClick"-->
                  <DxZoomAndPan
                    :drag-to-zoom="true"
                    pan-key="ctrl"
                    argument-axis="both"
                    value-axis="both"
                    :allow-touch-gestures="true"
                  />
                  <DxCommonSeriesSettings type="scatter" />
                  <DxSeries argument-field="sales" value-field="er">
                    <DxPoint
                      :color="getOutletStyle.primaryColor"
                      :size="scatterERLTM.points.size"
                    />
                  </DxSeries>
                  <DxTooltip
                    :enabled="true"
                    content-template="tooltipTemplate"
                    :argument-format="scatterERLTM.tooltip.argumentFormat"
                    :format="scatterERLTM.tooltip.valueFormat"
                    :interactive="true"
                  />

                  <DxArgumentAxis
                    :tick-interval="5"
                    :title="loc.EFFORTRATE.SCATTERARGAXISTITLE"
                  >
                    <DxGrid :visible="true" />
                    <DxMinorGrid :visible="false" />
                  </DxArgumentAxis>
                  <!--                      todo: applicare colore corretto a grid di entrambi gli assi (e fissare quella di asse argument - anche se di default già ok) -->
                  <!--                      todo: togliere primo e ultimo asse di argument grid -->
                  <DxValueAxis
                    :tick-interval="20"
                    :grid="{ visible: true }"
                    :title="loc.EFFORTRATE.SCATTERVALUEAXISTITLE"
                    :visible="false"
                  />
                  <DxLegend :visible="false" />
                  <DxCommonPaneSettings>
                    <DxBorder :visible="false" />
                  </DxCommonPaneSettings>

                  <template #tooltipTemplate="{ data }">
                    <TooltipTemplate :info="data" />
                  </template>
                </DxChart>
              </template>

              <template v-slot:componentTab>
                <!-- ## DATAGRID -->
                <DxDataGrid
                  id="dataGrid-filtered-by-client"
                  class="datagrid"
                  :data-source="
                    scatterDatagridERLTM.dataFilteredByProductCategory
                  "
                  key-expr="tenantId"
                  :show-borders="false"
                  :loadPanel="{ enabled: true }"
                  @exporting="onExportingDatagridEr"
                  :show-column-lines="false"
                  :show-row-lines="true"
                  :column-auto-width="true"
                  :column-hiding-enabled="true"
                  :noDataText="loc.COMMON.NODATA"
                >
                  <DxPaging :enabled="false" />
                  <DxExport :enabled="true" />
                  <DxColumn
                    data-field="tenant"
                    :caption="loc.EFFORTRATE.DATAGRIDCOL1CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style cell-body-head"
                    cell-template="diff-cell-template"
                  />
                  <DxColumn
                    :allow-sorting="false"
                    data-field="tenantId"
                    caption=""
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                    cell-template="link-cell-template"
                  />
                  <DxColumn
                    data-field="prodCat"
                    :caption="loc.EFFORTRATE.DATAGRIDCOL2CAPTION"
                    data-type="string"
                    alignment="left"
                    css-class="cell-custom-style"
                  />
                  <DxColumn
                    css-class="cell-custom-style"
                    data-field="sales"
                    :caption="loc.EFFORTRATE.DATAGRIDCOL3CAPTION"
                    data-type="number"
                    alignment="left"
                    :customizeText="customizeDatagridDataDecimals"
                  >
                    <DxFormat type="fixedPoint" :precision="0" />
                  </DxColumn>
                  <DxColumn
                    css-class="cell-custom-style"
                    data-field="er"
                    :caption="loc.EFFORTRATE.DATAGRIDCOL4CAPTION"
                    data-type="number"
                    alignment="left"
                    :customizeText="customizeDatagridDataPercentage"
                    sort-order="asc"
                  >
                    <DxFormat type="fixedPoint" :precision="2" />
                  </DxColumn>

                  <!-- template personalizzato celle datagrid -->
                  <template #diff-cell-template="{ data }">
                    <DiffCell :cell-data="data" />
                  </template>

                  <template #link-cell-template="{ data }">
                    <LinkCellTemplate :data="data" />
                  </template>
                </DxDataGrid>
              </template>
            </basic-chart-datagrid-visualizer>
          </div>
        </template>
      </DxItem>

      <!-- ############## 3rd row ############## -->
      <DxItem>
        <DxLocation :row="3" :col="0" :colspan="3" screen="xs sm md lg" />
        <template #default>
          <basic-datagrid-visualizer
            :componentTitle="loc.EFFORTRATE.DATAGRIDWORSTTITLE"
            :refDate="refDateLTM"
            :comparison-date="comparisonDateLTM"
            :comparison-date-comment="
              loc.EFFORTRATE.DATAGRIDWORSTBESTREFDATECOMMENT
            "
            :dataReady="effortRateDataReady"
          >
            <template v-slot:componentTab>
              <!--                  datagrid  -->
              <!--                  <DxDataGrid-->
              <!--                      id="dataGrid-worst-er"-->
              <!--                      class="datagrid"-->
              <!--                      :data-source="dataFilteredByProductCategory"-->
              <!--                      key-expr="productCategoryID"-->
              <!--                      :show-borders="false"-->
              <!--                      :loadPanel="{enabled: true}"-->
              <!--                  >-->
              <DxDataGrid
                id="dataGrid-worst-er"
                class="datagrid"
                :data-source="
                  datagridERWorstPerformer.dataFilteredByProductCategory
                "
                key-expr="tenantId"
                :show-borders="false"
                :loadPanel="{ enabled: true }"
                :show-column-lines="false"
                :show-row-lines="true"
                :column-auto-width="true"
                :column-hiding-enabled="true"
                :noDataText="loc.COMMON.NODATA"
              >
                <DxPaging :enabled="false" />
                <DxExport :enabled="true" />
                <DxColumn
                  :allow-sorting="false"
                  data-field="tenant"
                  :caption="loc.EFFORTRATE.DATAGRIDWORSTBESTCOL0CAPTION"
                  data-type="string"
                  alignment="left"
                  css-class="cell-custom-style cell-body-head"
                  width="249"
                  cell-template="diff-cell-template"
                />
                <DxColumn
                  :allow-sorting="false"
                  data-field="tenantId"
                  caption=""
                  data-type="string"
                  alignment="left"
                  css-class="cell-custom-style"
                  cell-template="link-cell-template"
                />
                <DxColumn
                  :allow-sorting="false"
                  data-field="ranking"
                  :caption="loc.EFFORTRATE.DATAGRIDWORSTBESTCOL1CAPTION"
                  data-type="number"
                  alignment="left"
                  :customizeText="customizeDatagridDataDecimals"
                  css-class="cell-custom-style"
                >
                  <DxFormat type="fixedPoint" :precision="0" />
                </DxColumn>
                <DxColumn
                  :allow-sorting="false"
                  data-field="salesLTM"
                  :caption="loc.EFFORTRATE.DATAGRIDWORSTBESTCOL2CAPTION"
                  data-type="number"
                  alignment="left"
                  :customizeText="customizeDatagridDataDecimals"
                  css-class="cell-custom-style"
                >
                  <DxFormat type="fixedPoint" :precision="0" />
                </DxColumn>

                <DxColumn
                  :allow-sorting="false"
                  data-field="salesLTMPY"
                  :caption="loc.EFFORTRATE.DATAGRIDWORSTBESTCOL3CAPTION"
                  data-type="number"
                  alignment="left"
                  :customizeText="customizeDatagridDataDecimals"
                  css-class="cell-custom-style"
                >
                  <DxFormat type="fixedPoint" :precision="0" />
                </DxColumn>
                <DxColumn
                  :allow-sorting="false"
                  data-field="varSales"
                  :caption="loc.EFFORTRATE.DATAGRIDWORSTBESTCOL4CAPTION"
                  data-type="number"
                  alignment="left"
                  :customizeText="customizeDatagridDataPercentage"
                  css-class="cell-custom-style"
                  cell-template="posNegCellTemplateSales"
                >
                  <DxFormat type="fixedPoint" :precision="2" />
                </DxColumn>

                <DxColumn
                  :allow-sorting="false"
                  data-field="erLTM"
                  :caption="loc.EFFORTRATE.DATAGRIDWORSTBESTCOL5CAPTION"
                  data-type="number"
                  alignment="left"
                  :customizeText="customizeDatagridDataPercentage"
                  css-class="cell-custom-style"
                >
                  <DxFormat type="fixedPoint" :precision="2" />
                </DxColumn>

                <DxColumn
                  :allow-sorting="false"
                  data-field="erLTMPY"
                  :caption="loc.EFFORTRATE.DATAGRIDWORSTBESTCOL6CAPTION"
                  data-type="number"
                  alignment="left"
                  :customizeText="customizeDatagridDataPercentage"
                  css-class="cell-custom-style"
                >
                  <DxFormat type="fixedPoint" :precision="2" />
                </DxColumn>

                <DxColumn
                  :allow-sorting="false"
                  data-field="varER"
                  :caption="loc.EFFORTRATE.DATAGRIDWORSTBESTCOL7CAPTION"
                  data-type="number"
                  alignment="left"
                  :customizeText="customizeDatagridDataPercentage"
                  css-class="cell-custom-style"
                  cell-template="posNegCellTemplateEr"
                >
                  <DxFormat type="fixedPoint" :precision="2" />
                </DxColumn>
                <!--                    <DxColumn-->
                <!--                        data-field="effortRatePercentLTM"-->
                <!--                        caption="Sale Amount"-->
                <!--                        data-type="number"-->
                <!--                        alignment="left"-->
                <!--                    />-->

                <!--                    <DxColumn-->
                <!--                        :allow-grouping="false"-->
                <!--                        data-field="Discount"-->
                <!--                        caption="Discount %"-->
                <!--                        data-type="number"-->
                <!--                        format="percent"-->
                <!--                        alignment="right"-->
                <!--                        cell-template="discountCellTemplate"-->
                <!--                        css-class="bullet"-->
                <!--                    />-->

                <!-- template personalizzato celle datagrid -->
                <template #diff-cell-template="{ data }">
                  <DiffCell :cell-data="data" />
                </template>

                <template #posNegCellTemplateSales="{ data }">
                  <div
                    :class="
                      data.value != null
                        ? data.value < 0
                          ? 'negative-value'
                          : ''
                        : ''
                    "
                  >
                    {{ data.text }}
                  </div>
                </template>

                <template #posNegCellTemplateEr="{ data }">
                  <div
                    :class="
                      data.value != null
                        ? data.value > 0
                          ? 'negative-value'
                          : ''
                        : ''
                    "
                  >
                    {{ data.text }}
                  </div>
                </template>

                <template #link-cell-template="{ data }">
                  <LinkCellTemplate :data="data" />
                </template>
              </DxDataGrid>
            </template>
          </basic-datagrid-visualizer>
        </template>
      </DxItem>

      <!-- ############## 4th row ############## -->
      <DxItem>
        <DxLocation :row="4" :col="0" :colspan="3" screen="xs sm md lg" />
        <template #default>
          <basic-datagrid-visualizer
            :componentTitle="loc.EFFORTRATE.DATAGRIDBESTTITLE"
            :refDate="refDateLTM"
            :comparison-date="comparisonDateLTM"
            :comparison-date-comment="
              loc.EFFORTRATE.DATAGRIDWORSTBESTREFDATECOMMENT
            "
            :dataReady="effortRateDataReady"
          >
            <template v-slot:componentTab>
              <DxDataGrid
                id="dataGrid-best-er"
                class="datagrid"
                :data-source="
                  datagridERBestPerformer.dataFilteredByProductCategory
                "
                key-expr="tenantId"
                :show-borders="false"
                :loadPanel="{ enabled: true }"
                :show-column-lines="false"
                :show-row-lines="true"
                :column-hiding-enabled="true"
                :column-auto-width="true"
                :noDataText="loc.COMMON.NODATA"
              >
                <DxExport :enabled="true" />
                <DxPaging :enabled="false" />
                <DxColumn
                  :allow-sorting="false"
                  data-field="tenant"
                  :caption="loc.EFFORTRATE.DATAGRIDWORSTBESTCOL0CAPTION"
                  data-type="string"
                  alignment="left"
                  css-class="cell-custom-style cell-body-head"
                  width="249"
                  cell-template="diff-cell-template"
                />
                <DxColumn
                  :allow-sorting="false"
                  data-field="tenantId"
                  caption=""
                  data-type="string"
                  alignment="left"
                  css-class="cell-custom-style"
                  cell-template="link-cell-template"
                />
                <DxColumn
                  :allow-sorting="false"
                  data-field="ranking"
                  :caption="loc.EFFORTRATE.DATAGRIDWORSTBESTCOL1CAPTION"
                  data-type="number"
                  alignment="left"
                  :customizeText="customizeDatagridDataDecimals"
                  css-class="cell-custom-style"
                >
                  <DxFormat type="fixedPoint" :precision="0" />
                </DxColumn>

                <DxColumn
                  :allow-sorting="false"
                  data-field="salesLTM"
                  :caption="loc.EFFORTRATE.DATAGRIDWORSTBESTCOL2CAPTION"
                  data-type="number"
                  alignment="left"
                  :customizeText="customizeDatagridDataDecimals"
                  css-class="cell-custom-style"
                >
                  <DxFormat type="fixedPoint" :precision="0" />
                </DxColumn>

                <DxColumn
                  :allow-sorting="false"
                  data-field="salesLTMPY"
                  :caption="loc.EFFORTRATE.DATAGRIDWORSTBESTCOL3CAPTION"
                  data-type="number"
                  alignment="left"
                  :customizeText="customizeDatagridDataDecimals"
                  css-class="cell-custom-style"
                >
                  <DxFormat type="fixedPoint" :precision="0" />
                </DxColumn>
                <DxColumn
                  :allow-sorting="false"
                  data-field="varSales"
                  :caption="loc.EFFORTRATE.DATAGRIDWORSTBESTCOL4CAPTION"
                  data-type="number"
                  alignment="left"
                  :customizeText="customizeDatagridDataPercentage"
                  css-class="cell-custom-style"
                  cell-template="posNegCellTemplateSales"
                >
                  <DxFormat type="fixedPoint" :precision="2" />
                </DxColumn>

                <DxColumn
                  :allow-sorting="false"
                  data-field="erLTM"
                  :caption="loc.EFFORTRATE.DATAGRIDWORSTBESTCOL5CAPTION"
                  data-type="number"
                  alignment="left"
                  :customizeText="customizeDatagridDataPercentage"
                  css-class="cell-custom-style"
                >
                  <DxFormat type="fixedPoint" :precision="2" />
                </DxColumn>

                <DxColumn
                  :allow-sorting="false"
                  data-field="erLTMPY"
                  :caption="loc.EFFORTRATE.DATAGRIDWORSTBESTCOL6CAPTION"
                  data-type="number"
                  alignment="left"
                  :customizeText="customizeDatagridDataPercentage"
                  css-class="cell-custom-style"
                >
                  <DxFormat type="fixedPoint" :precision="2" />
                </DxColumn>

                <DxColumn
                  :allow-sorting="false"
                  data-field="varER"
                  :caption="loc.EFFORTRATE.DATAGRIDWORSTBESTCOL7CAPTION"
                  data-type="number"
                  alignment="left"
                  :customizeText="customizeDatagridDataPercentage"
                  css-class="cell-custom-style"
                  cell-template="posNegCellTemplateEr"
                >
                  <DxFormat type="fixedPoint" :precision="2" />
                </DxColumn>

                <!-- template personalizzato celle datagrid -->
                <template #diff-cell-template="{ data }">
                  <DiffCell :cell-data="data" />
                </template>

                <template #posNegCellTemplateSales="{ data }">
                  <div
                    :class="
                      data.value != null
                        ? data.value < 0
                          ? 'negative-value'
                          : ''
                        : ''
                    "
                  >
                    {{ data.text }}
                  </div>
                </template>

                <template #posNegCellTemplateEr="{ data }">
                  <div
                    :class="
                      data.value != null
                        ? data.value > 0
                          ? 'negative-value'
                          : ''
                        : ''
                    "
                  >
                    {{ data.text }}
                  </div>
                </template>

                <template #link-cell-template="{ data }">
                  <LinkCellTemplate :data="data" />
                </template>
              </DxDataGrid>
            </template>
          </basic-datagrid-visualizer>
        </template>
      </DxItem>

      <!--   footer   -->
      <!--          <DxItem>-->
      <!--            <DxLocation :row="5" :col="2" :colspan="1" screen="lg xs sm md" />-->
      <!--            <template #default>-->
      <!--              <div class="app-version">v. {{ getStoredAppVersion.versionNumber }} - {{ getStoredAppVersion.versionDate }}</div>-->
      <!--            </template>-->
      <!--          </DxItem>-->
    </DxResponsiveBox>
  </div>
</template>

<script>
import auth from "@/auth";
import itMessages from "devextreme/localization/messages/it.json";
import { locale, loadMessages } from "devextreme/localization";

import { loc } from "@/config/languages/en.js";

import DxSelectBox from "devextreme-vue/select-box";
import "devextreme/dist/css/dx.light.css";
import ApiCalls from "@/services/ApiCalls";

import { mapGetters } from "vuex";
import DxCalendar from "devextreme-vue/calendar";

import {
  DxResponsiveBox,
  DxItem,
  DxLocation,
  DxCol,
  DxRow,
} from "devextreme-vue/responsive-box";

/******************************************************************************************************
 * # COMPONENTE - INIZIO
 **/

import { DxBarGauge } from "devextreme-vue/bar-gauge";

import DxDataGrid, {
  // DxLoadPanel,
  DxPaging,
  DxColumn,
  DxFormat,
  DxExport,
} from "devextreme-vue/data-grid";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";

import {
  DxChart,
  DxSeries,
  DxPoint,
  DxCommonSeriesSettings,
  DxLegend,
  DxValueAxis,
  DxArgumentAxis,
  DxGrid,
  DxMinorGrid,
  DxCommonPaneSettings,
  DxBorder,
  DxTooltip,
  DxZoomAndPan,

  // DxSize,
} from "devextreme-vue/chart";

import // DxFormat,
// DxLabel,
// DxConnector,
// DxExport
"devextreme-vue/pie-chart";

import DxButton from "devextreme-vue/button";

import DiffCell from "@/components/datagrid/DiffCell.vue"; // componente per template personalizzato celle datagrid

import TooltipTemplate from "@/components/chart/TooltipTemplate.vue";
/**
 * # /COMPONENTE - FINE
 ******************************************************************************************************/

export default {
  components: {
    DxResponsiveBox,
    DxItem,
    DxLocation,
    DxCol,
    DxRow,
    DxSelectBox,
    DxButton,
    DxCalendar,

    /******************************************************************************************************
     * # COMPONENTE - INIZIO
     **/
    DxDataGrid,
    // DxLoadPanel,
    DxPaging,
    DxExport,

    DxChart,
    DxSeries,
    DxPoint,
    DxCommonSeriesSettings,
    DxLegend,
    DxValueAxis,
    DxArgumentAxis,
    DxGrid,
    DxMinorGrid,
    DxCommonPaneSettings,
    DxBorder,
    DxTooltip,
    // DxSize,
    DxColumn,
    DxFormat,

    // DxPieChart,
    // DxFormat,
    // DxLabel,
    // DxConnector,
    // DxExport,

    DxBarGauge,
    DxZoomAndPan,

    DiffCell, // componente per template personalizzato celle datagrid

    TooltipTemplate,

    /**
     * # /COMPONENTE - FINE
     ******************************************************************************************************/
  },

  data() {
    /***************
     * # PROVVISORIO: ARRIVERà DA VUEX
     ***************/
    // let outlet = this.$store.getters.getSelectedOutlet.id ? this.$store.getters.getSelectedOutlet.id : 1; // #1
    // let refDate = {year: 2021, month: 10};
    let selectedProductCategory = 0;
    // console.log("er outlet from store: ", outlet)

    return {
      /***************
       * # PROVVISORIO: ARRIVERà DA VUEX
       ***************/
      // parametri per composizione chiamate - prov (arriveranno da vuex, impostato da subheader/partials/drop-down component)
      // outletId: outlet, /* #globale */
      // refDate: refDate, /* #globale */

      /** ## BAR GAUGE **********/
      barGaugesLabelsSettings: {
        indent: 10,
        format: this.format,
        customizeText: this.customizeText,
        font: this.barGaugeLabelFont,
      },

      /******************************************************************************************************
       * # COMPONENTE - INIZIO
       **/

      /** ## DATE PER I COMPONENTI **********/
      refDateLTM: "",
      comparisonDateLTM: "",

      refDateText: "", // arriva da chiamata refDate.plain
      comparisonDateText: "", // arriva da chiamata comparisonDate.plain

      selectedProductCategory: selectedProductCategory, // #globale

      /** ## SELETTORE CATEGORIE MERCEOLOGICHE **********/
      categories: [], // todo: globale o componente? ogni quanto si ripopolerà? verrà preso da localstorage dopo prima chiamata?

      // DATAGRID CATEGORIES COMPONENT
      // selectedCategory: null, // forse non più utile... #check
      /**
       * # /COMPONENTE - FINE
       ******************************************************************************************************/

      /***************
       * # GENERALI E GLOBALI
       ***************/
      effortRateData: null /************* # COMPONENTE *********************************/,
      // dataFilteredByProductCategory: [], // #componente

      // flag per abilitare eventuali pulsanti solo quando i dati sono stati ricevuti
      effortRateDataReady: false /************* # COMPONENTE *********************************/,

      // flag per abilitare filtro categorie merceologiche (dipende da pagina - domain) #n
      productCategoryFilterDisabledByPage: null,

      /** ## LOCALIZZAZIONE **********/
      locale: "it",

      loc: loc, // labels

      /** ## BREAKPOINTS **********/
      screenByWidth: function(width) {
        if (width < 768) return "xs";
        if (width < 1024) return "sm";
        if (width < 1150) return "md";
        // if (width < 1150) return "md";
        return "lg";
      },

      //Settings
      settingsZoomLevel: "decade",
      settingsMaxSelectableDate: null,
      settingsMinSelectableDate: null,
      settingsHidden: true,

      /** ## CALENDAR **********/
      calendarZoomLevel:
        "year" /* #componente: impostata di default, dipende da pagina: usata per cambiare stato active */,
      maxSelectableDate: null,
      minSelectableDate: null, // #n
      calendarHidden: true,

      /******************************************************************************************************
       * # COMPONENTE - INIZIO
       **/

      /** ## BAR GAUGE **********/
      format: {
        /* #globale */
        type: "fixedPoint",
        precision: 1,
      },

      /** ## DATAGRID **********/
      customizeDatagridDataDecimals(value) {
        /* #globale */
        return value.valueText;
        // return value.valueText + " €";
      },

      customizeDatagridDataPercentage(value) {
        /* #globale */
        return value.valueText;
        // return value.valueText + " %";
      },

      // datagrid loader chiamata filtrata via api: ancora utile?
      loadingVisible: true /* #componente */,

      /***************
       * # COMPONENT ER LTM DISTRIBUTION - BAR GAUGE
       ***************/
      // todo: trasformare in oggetto ogni componente (solo var componente)?

      barGaugeER: {
        // #componente
        dataRef: [],
        dataComparison: [],
        legend: {},
      },

      barGaugeGeometry: {
        /* #globale */
        endAngle: -30,
        startAngle: 210,
      },
      colorGood: "#59C47F" /* #globale */,
      colorNormal: "#FFD341" /* #globale */,
      colorBad: "#FF5633" /* #globale */,
      barGaugeLabelFont: {
        family: "Nunito, sans-serif",
        size: 18,
        weight: 400,
      } /* #globale */,
      // todo: label font-size a 15 in 768 e 1024

      /***************
       * # COMPONENT ER LTM
       ***************/
      scatterDatagridERLTM: {
        // componentTitle: "Effort Rate LTM %",
        dataFilteredByProductCategory: null,
      },

      /** ## SCATTER PLOT **********/
      dataDisplayMode:
        "chart" /* #componente: serve a togliere il reset zoom button in seguito ad azione su switch (risolve problema su ipad con chrome) */,

      effortRateDisplayMode: "chart" /* #componente */,

      scatterErInstance: null /* #componente */,

      scatterChartCommonAxisSettings: {
        /* #globale */
        //todo: color non viene applicato: scoprire cosa lo sovrascrive
        label: {
          font: { size: 14, family: "Nunito", weight: 400, color: "#787878" },
        },
        title: {
          margin: 14,
          font: { size: 14, family: "Nunito", weight: 400, color: "#666666" },
        },
      },

      scatterERLTM: {
        // argumentAxis: {
        //   title: { text: 'Net Sales (€)'},
        // },
        // valueAxis: {
        //   title: { text: 'E.R. (%)'},
        // },
        points: {
          size: 20,
        },
        tooltip: {
          argumentFormat: { type: "fixedPoint", precision: 0 },
          valueFormat: { type: "decimal", precision: 0 },
        },
      },

      /***************
       * # COMPONENT WORST PERFORMER - DATAGRID
       ***************/
      datagridERWorstPerformer: {
        // componentTitle: "Worst Performers",
        dataFilteredByProductCategory: null,
      },

      /***************
       * # COMPONENT BEST PERFORMER - DATAGRID
       ***************/
      datagridERBestPerformer: {
        // componentTitle: "Best Performers",
        dataFilteredByProductCategory: null,
      },

      /**
       * # /COMPONENTE - FINE
       ******************************************************************************************************/
    };
  },

  computed: {
    ...mapGetters([
      "getSelectedOutlet",
      "getOutletsList",
      "getCategoriesList",
      "getOutletLastSalesDates",
      "getNewOutletLastSalesDates",
      "getOutletStyle",
      "getStoredAppVersion",
      "getLegendFontSettings",
    ]),

    /***************
     * # CALENDAR
     ***************/
    displayStringDate() {
      /* #globale */
      if (this.$store.state.selectedDate) {
        let year = this.$store.state.selectedDate.slice(0, 4);
        let month = this.fromOrdinalToMonth(
          Number(this.$store.state.selectedDate.slice(3, 5) - 1)
        );

        return `${month} ${year}`;
      } else {
        let year = this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate.slice(
          0,
          4
        );
        let month = this.fromOrdinalToMonth(
          Number(
            this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate.slice(
              3,
              5
            ) - 1
          )
        );

        return `${month} ${year}`;
      }
    },
  },

  methods: {
    changeDisplayData(value) {
      /* #componente: serve a togliere il reset zoom button in seguito ad azione su switch (risolve problema su ipad con chrome) */
      this.dataDisplayMode = value;
    },

    fromOrdinalToMonth(ordinalNumber) {
      /* #globale */
      // console.log("fn fromOrdinalToMonth called");
      let output = ordinalNumber;
      switch (ordinalNumber) {
        case 1:
          output = "Jan";
          break;
        case 2:
          output = "Feb";
          break;
        case 3:
          output = "Mar";
          break;
        case 4:
          output = "Apr";
          break;
        case 5:
          output = "May";
          break;
        case 6:
          output = "Jun";
          break;
        case 7:
          output = "Jul";
          break;
        case 8:
          output = "Aug";
          break;
        case 9:
          output = "Sep";
          break;
        case 10:
          output = "Oct";
          break;
        case 11:
          output = "Nov";
          break;
        case 12:
          output = "Dec";
          break;
        default:
          output = "-";
      }

      return output;
    },

    /***************
     * # CALENDAR
     ***************/
    // funzione per convertire numeri da una a due cifre
    convertFormatDate(date) {
      console.log("date: ", date);
      return String(date).length < 2 ? "0" + date : date;
    },

    onSelectedDate(e) {
      // console.log("selectedDate: ", new Date(e.value));
      let temp = new Date(e.value);
      // console.log("temp: ", temp);
      let selectedDate = `${temp.getFullYear()}${this.convertFormatDate(
        Number(temp.getMonth()) + 1
      )}${this.convertFormatDate(temp.getDate())}`;
      // console.log("selectedDate: ", selectedDate);

      // imposto data selezionata in vuex
      this.$store.dispatch("dispatchSelectedDate", selectedDate);
      console.log("selectedDate - vuex: ", this.$store.state.selectedDate);

      // chiamata api per riprendere dati pagina er (tenendo conto di categoria selezionata)
      this.getEffortRateData();

      // nascondi il calendario
      this.calendarHidden = true;
    },

    onSelectedSetting(e) {
      let temp = new Date(e.value);
      let selectedSetting = `${temp.getFullYear()}`;
      this.$store.dispatch("dispatchSelectedSetting", selectedSetting);

      this.getEffortRateData();
      this.settingsHidden = true;
    },

    // funzione per impostare il range di date selezionabili (month, week)
    onCalendarZoomLevelSelection(calendarZoomLevelSelected) {
      // impostazione zoom level calendario
      this.calendarZoomLevel = calendarZoomLevelSelected.value;
      console.log("calendar zoom level: ", this.calendarZoomLevel);

      // imposta ultima data selezionabile
      this.setMaxSelectableDate();
    },

    // imposta ultima data selezionabile
    setMaxSelectableDate() {
      if (this.calendarZoomLevel === "year") {
        // console.log("prova start date: ", this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate);
        let tempLastDateYear = (
          this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + ""
        ).slice(0, 4);
        let tempLastDateMonth = (
          this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + ""
        ).slice(4, 6);

        // ricavo data di partenza in formato data
        let tempLastDate = new Date(tempLastDateYear, tempLastDateMonth, 0);

        // ricreo la data in formato devextreme e la imposto nel calendario
        this.maxSelectableDate = `${tempLastDate.getFullYear()}${(
          tempLastDate.getMonth() + 1
        ).toLocaleString(undefined, {
          minimumIntegerDigits: 2,
        })}${tempLastDate.getDate()}`; // #n

        // console.log("calendar max selectable date: ", this.maxSelectableDate);
        // console.log("calendar max selectable date - old: ", this.$store.state.outletLastSalesDates.salesLastDayClosedMonth);  // old

        //  imposto min data selezionabile su calendario // #n
        console.log(
          "prova start first date: ",
          this.$store.state.newOutletLastSalesDates.firstAvailableSalesDate
        );
        let tempFirstDateYear = (
          this.$store.state.newOutletLastSalesDates.firstAvailableSalesDate + ""
        ).slice(0, 4);
        let tempFirstDateMonth = (
          this.$store.state.newOutletLastSalesDates.firstAvailableSalesDate + ""
        ).slice(4, 6);

        // ricavo data di partenza in formato data
        let tempFirstDate = new Date(tempFirstDateYear, tempFirstDateMonth, 1);
        console.log("temp first date: ", tempFirstDate);

        // ricreo la data in formato devextreme e la imposto nel calendario
        this.minSelectableDate = `${tempFirstDate.getFullYear()}${tempFirstDate
          .getMonth()
          .toLocaleString(undefined, {
            minimumIntegerDigits: 2,
          })}${tempFirstDate
          .getDate()
          .toLocaleString(undefined, { minimumIntegerDigits: 2 })}`; // #n

        this.settingsMaxSelectableDate = `${tempLastDate.getFullYear() - 1}${(
          tempLastDate.getMonth() + 1
        ).toLocaleString(undefined, {
          minimumIntegerDigits: 2,
        })}${tempLastDate.getDate()}`; // #n;
        this.settingsMinSelectableDate = "2018";
      }

      // if(this.calendarZoomLevel === "month") { // per ora non utilizzato !? attenzione: da aggiornare con lastAvailableSalesDate !? // #n
      //   this.maxSelectableDate = this.$store.state.outletLastSalesDates.salesLastDayClosedWeek;
      // }

      console.log("max selectable data: ", this.maxSelectableDate);
      console.log("calendar min selectable date: ", this.minSelectableDate);
    },

    onShowCalendar() {
      this.calendarHidden === true
        ? (this.calendarHidden = false)
        : (this.calendarHidden = true);
    },

    onShowSettings() {
      this.settingsHidden === true
        ? (this.settingsHidden = false)
        : (this.settingsHidden = true);
    },

    // getCellCssClass(date) {
    //   let cssClass = '';
    //   const holydays = [[1, 0], [4, 6], [25, 11]];
    //
    //   if (this.isWeekend(date)) { cssClass = 'weekend'; }
    //
    //   holydays.forEach((item) => {
    //     if (date.getDate() === item[0] && date.getMonth() === item[1]) {
    //       cssClass = 'holyday';
    //     }
    //   });
    //
    //   return cssClass;
    // },

    /***************
     * # GENERALE
     ***************/
    onDrawn(e) {
      /* #globale */
      setTimeout(function() {
        e.component.render();
      }, 1);
    },

    /** ## FILTRO OUTLETS - parte 1: impostazione filtro e chiamate api **********/
    onOutletSelected(e) {
      console.log("selezionato outlet: ", e.value);

      // condizione per pulsante clear: onclick passa null -> defaultOutlet
      let selectedOutletId = e.value == null ? 1 : e.value;

      // ricavo l'outlet dall'id selezionato (in vuex selectedOutlet è l'obj completo)
      let selectedOutlet = this.$store.state.outletsList.filter(
        (outlet) => outlet.id == selectedOutletId
      )[0];

      // invio l'outlet selezionato a vuex
      this.$store.dispatch("dispatchSelectedOutlet", selectedOutlet);

      console.log("selected outlet - vuex: ", this.$store.state.selectedOutlet);

      // al cambio di outlet, reimposto le categorie a 0 (all): non tutti gli outlets hanno le stesse categorie
      this.$store.dispatch("dispatchSelectedCategory", 0);
      // reimpostare anche selectbox categorie
      console.log(
        "on outlet change, reset selected category - vuex: ",
        this.$store.state.selectedCategory
      );

      // recupero le categorie dell'outlet e le imposto nel vuex
      this.getDataByCategories();

      // reimposto le ultime date selezionabili in calendario
      this.setMaxSelectableDate();
    },

    /** ## CHIAMATA API FILTRO OUTLETS (anche chiamata iniziale pagina) **********/
    getEffortRateData() {
      console.log("getEffortRateData called");

      // recupero variabili per chiamata da store
      let outletId = this.$store.state.selectedOutlet.id;
      // console.log("api call - outletId: ", outletId);

      let outletsDatesYear = "";
      let outletsDatesMonth = "";

      let yearComp = "";

      // controllo che selected date non sia impostata: se == null, cambiato selezione outlet o primo caricamento pagina: perciò prendo ultima data da outlet
      if (this.$store.state.selectedDate == null) {
        //#n
        outletsDatesYear = (
          this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + ""
        ).slice(0, 4); // nel caso arrivi un numero, trasformo in stringa // #n
        console.log("api call - outletsDatesYear", outletsDatesYear);
        // outletsDatesMonth = 10; // temp per test: non ci sono tutti i dati per l'ultimo mese fornito dalle api  // todo: ricordati di passare alla modalità non test
        outletsDatesMonth = (
          this.$store.state.newOutletLastSalesDates.lastAvailableSalesDate + ""
        ).slice(4, 6); // #n
        console.log("api call - outletsDatesMonth", outletsDatesMonth); // /#n
      } else {
        outletsDatesYear = this.$store.state.selectedDate.slice(0, 4);
        console.log("api call - outletsDatesYear", outletsDatesYear);
        // outletsDatesMonth = 10;
        outletsDatesMonth = this.$store.state.selectedDate.slice(4, 6);
        console.log("api call - outletsDatesMonth", outletsDatesMonth);
      }

      if (this.$store.state.selectedSettings == null) {
        yearComp = outletsDatesYear - 1;
      } else {
        yearComp = this.$store.state.selectedSettings;
      }

      ApiCalls.getEffortRate(
        outletId,
        outletsDatesYear,
        outletsDatesMonth,
        yearComp
      )
        .then((res) => {
          console.log("chiamata api er res: ", res.data);

          // #n
          // imposta dominio in vuex per decidere quali filtri lasciare attivi
          let currentDomain = res.data.metadata.domain;
          this.$store.dispatch("dispatchCurrentDomain", currentDomain);
          // console.log("vuex domain: ", this.$store.state.currentDomain);

          // flag per disabilitare filtri a seconda della pagina
          if (
            this.$store.state.pagesDisabledFilters.productCategoryFilter.some(
              (domain) => domain == currentDomain
            )
          ) {
            this.productCategoryFilterDisabledByPage = true;

            // se filtro disabilitato, visualizza tutte le categorie merceologiche
            this.$store.dispatch("dispatchSelectedCategory", 0);
          } else {
            this.productCategoryFilterDisabledByPage = false;
          }
          // console.log("productCategoryFilterDisabledByPage: ", this.productCategoryFilterDisabledByPage);
          // /#n

          // salvataggio dati pagina er
          this.effortRateData =
            res.data; /* #componente */ /************* # COMPONENTE *********************************/

          // fn a parte (cambia per singolo componente): distribuisce dati ai vari componenti
          this.setComponentsData();
          // /fn a parte
        })
        .catch((err) => console.log(err.response));
    },

    /** ## CHIAMATA LOCALE FILTRO CLIENT - CATEGORIE **********/
    onProductCategorySelected(e) {
      // imposto selectedCategory in vuex
      console.log("selezionato categoria: ", e.value);
      e.value == null
        ? this.$store.dispatch("dispatchSelectedCategory", 0)
        : this.$store.dispatch("dispatchSelectedCategory", e.value);

      console.log(
        "selected category - vuex: ",
        this.$store.state.selectedCategory
      );

      // rivalorizzo i dataset dei vari componenti
      this.setComponentsData();
    },

    /** ## VALORIZZAZIONE DATASET DEI VARI COMPONENTI **********/
    setComponentsData() {
      /******************************************************************************************************
       * # COMPONENTE - INIZIO
       **/

      let result = this.effortRateData
        .result; /************* # COMPONENTE *********************************/
      let metadata = this.effortRateData
        .metadata; /************* # COMPONENTE *********************************/
      let selectedProductCategory = this.$store.state.selectedCategory;

      // proprietà che verrà usata per filtrare i tenants a seconda delle categorie di prodotto: se categoria negativa, fitrerò su food / non food, altrimenti su categoria prodotto
      let targetProperty =
        selectedProductCategory < 0 ? "prodCatClusterId" : "prodCatId";

      // todo: prob, chiamata api salverà dati in variabile, poi chiamerà immediatamente filtro locale per distribuire i dati ai vari componenti; quando categoria prodotto viene chiamata, parte filtro locale; quando outlet o data vengono cambiatai parte chiamata api (che al suo interno ha chiamata a filtro locale)
      /***** ### impostazione dati iniziali bar gauge *****/

      // todo: cambia con filter
      // bar gauge ref's data (LTM)
      let temp = {};
      temp = result.effortRateClusteringLTM.filter(
        (obj) => obj[targetProperty] === selectedProductCategory
      )[0];
      temp
        ? (this.barGaugeER.dataRef = [
            temp.goodPercentage,
            temp.normalPercentage,
            temp.badPercentage,
          ])
        : [0, 0, 0];
      // console.log("gauge legend ltm: ", this.barGaugeER.dataRef);

      // bar gauge comparison's data (LTMPY)
      temp = result.effortRateClusteringLTMPY.filter(
        (obj) => obj[targetProperty] === selectedProductCategory
      )[0];
      temp
        ? (this.barGaugeER.dataComparison = [
            temp.goodPercentage,
            temp.normalPercentage,
            temp.badPercentage,
          ])
        : [0, 0, 0];
      // console.log("gauge legend ltmpy: ", this.barGaugeER.dataComparison);

      // bar gauge dates
      this.refDateLTM = metadata.periods.find(
        (obj) => obj.type == "LTM"
      ).plainAlt;
      this.comparisonDateLTM = metadata.periodsComparison.find(
        (obj) => obj.type == "LTM"
      ).plainAlt;
      // metadata.periods[0] && metadata.periods[0].type === "REF" ?
      //     this.refDateText = metadata.periods[0].plain :
      //     this.refDateText = "not specified";
      //
      // metadata.periodsComparison[0] && metadata.periodsComparison[0].type === "REF" ?
      //     this.comparisonDateText = metadata.periodsComparison[0].plain :
      //     this.comparisonDateText = "not specified";

      // bar gauge legend
      this.barGaugeER.legend = metadata.effortRateClusteringThresholds;
      console.log("barGaugeER.legend", this.barGaugeER.legend);

      console.log("selectedProductCategory: ", selectedProductCategory);

      /** ## DATA FOR SCATTER E DATAGRID **********/
      /***** ### scatter-datagrid er ltm *****/
      this.scatterDatagridERLTM.dataFilteredByProductCategory =
        selectedProductCategory === 0
          ? result.effortRatesLTM
          : result.effortRatesLTM.filter(
              (el) => el[targetProperty] === selectedProductCategory
            );
      console.log(
        "scatterDatagridERLTM.dataFilteredByProductCategory all: ",
        this.scatterDatagridERLTM.dataFilteredByProductCategory
      );

      /***** ### datagrid wp *****/
      this.datagridERWorstPerformer.dataFilteredByProductCategory = result.worstEffortRatesLTM.filter(
        (el) => el[targetProperty] === selectedProductCategory
      )[0].items;
      console.log(
        "datagridERWorstPerformer.dataFilteredByProductCategory all: ",
        this.datagridERWorstPerformer.dataFilteredByProductCategory
      );

      /***** ### datagrid bp *****/
      this.datagridERBestPerformer.dataFilteredByProductCategory = result.bestEffortRatesLTM.filter(
        (el) => el[targetProperty] === selectedProductCategory
      )[0].items;
      console.log(
        "datagridERBestPerformer.dataFilteredByProductCategory all: ",
        this.datagridERBestPerformer.dataFilteredByProductCategory
      );

      /***** ### flag per abilitare eventuali button o select *****/
      this.effortRateDataReady = true; /* #componente */

      /**
       * # /COMPONENTE - FINE
       ******************************************************************************************************/
    },

    /******************************************************************************************************
     * # COMPONENTE - INIZIO
     **/

    /***************
     * # BAR GAUGE
     ***************/
    customizeText({ valueText }) {
      return `${valueText} %`;
    },

    customizeBarGaugeLegendText({ text, item }) {
      if (item.index == 0) {
        return `Good (${text}%)`;
      }

      if (item.index == 1) {
        return `Normal (${text}%)`;
      }

      if (item.index == 2) {
        return `Bad (${text}%)`;
      }
    },

    /***************
     * # DATAGRID
     ***************/
    /** ## EXPORT BUTTON **********/
    onExportingDatagridEr(e) {
      /* #globale */
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function(options) {
          const excelCell = options;
          excelCell.font = { name: "Arial", size: 12 };
          excelCell.alignment = { horizontal: "left" };
        },
      }).then(function() {
        workbook.xlsx.writeBuffer().then(function(buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "DataGrid.xlsx"
          );
        });
      });
      e.cancel = true;
    },

    /***************
     * # SCATTER
     ***************/
    /** ## RESET ZOOM BUTTON **********/
    saveScatterErInstance(e) {
      this.scatterErInstance = e.component;
    },

    resetScatterErZoom() {
      console.log(this.scatterErInstance);
      this.scatterErInstance.resetVisualRange();
      // this.scatterErInstance.ActualAxisY.ActualVisualRange.SetAuto();
    },

    /** ## TOOLTIP **********/
    // onPointClick({ target: point }) {
    //   point.showTooltip();
    //   // this.selectedRegion = point.argument;
    //https://js.devexpress.com/Demos/WidgetsGallery/Demo/Charts/APIDisplayATooltip/Vue/Light/
    // },

    // customizeTooltip(pointInfo) {
    //   console.log("tooltip: ", pointInfo)
    //   return {
    //     text: `Tenant: ${pointInfo.point["_dataItem"].data.tenant}<br>
    //     Net Sales: ${pointInfo.argumentText}<br>
    //     Effort Rate: ${pointInfo.valueText} %`,
    //   };
    // },

    //  DATAGRID WORST EFFORT RATE
    //   sortDataWorstER(data) {
    //     return data.sort((a,b) => b.effortRatePercentLTM - a.effortRatePercentLTM);
    //   },
  },

  /**
   * # /COMPONENTE - FINE
   ******************************************************************************************************/

  mounted() {
    auth.getUser().then((e) => {
      this.user = e.data;
    });
  },

  beforeCreate() {
    // this.outletId = this.$store.getters.getSelectedOutlet.id; // #1
  },

  created() {
    // -- primo accesso all'app

    // CHIAMATA API MAIN.JS INIZIALE
    // 0. main.js fa le varie chiamate api per prendere le liste dei filtri (store - e data -, categorie) e li salva in vuex -> ho i dati per popolare i filtri
    //    (_ controllo localStorage per outlet)
    //    (_ salva dati in local storage)

    // -- primo accesso alla pagina
    // "CHIAMATA LOCALE" A VUEX PER PRENDERE I FILTRI ->
    // 1. prendo dati per popolare i filtri da vuex via getters: store, prodCatId/, dates -> popolo i filtri

    // CHIAMATA API PAGINA ER INIZIALE -> fn api
    // 2. prendo i filtri selezionati da vuex (default per primo accesso: prodCatId = 0, store = 1, data = data corrente (mese, ... dipende da pagina))
    // 3. faccio chiamata api con filtri selezionati (punto 2) per popolare i componenti;
    //    _ salvo i dati della chiamata api in una variabile per poterli usare con il filtro lato client
    //    _ popolo i data-set dei vari componenti con la variabile appena valorizzata

    // -- / fine primo accesso alla pagina

    // CHIAMATA LOCALE FILTRO CLIENT (CATEGORIE) -> fn client
    // 4. al click su filtro categorie, chiamo fn client
    // 5. fn:
    //    _ salva il filtro selezionato in vuex
    //    _ prendo i dati er salvati nella variabile locale, li filtro e li salvo nei dataset dei vari componenti

    // CHIAMATA API FILTRO DATA / OUTLET
    // 6. al click su filtro data / outlet,
    // 7. salvo filtro selezionato su vuex -> fn (una commit, action per variabile o una per tutte e 3?)
    // 8. chiamo fn api

    /***************
     * # LOCALIZZAZIONE
     ***************/
    loadMessages(itMessages);
    locale(this.locale);

    // #todo: adottare sintassi con params xé + chiara?

    // console.log("vuex: ", this.$store);

    /***************
     * # 0: main.js
     ***************/
    this.getOutletsData();
  },
};

// todo: chiedere perché componenti scoped prevengono alcune regole css (es: css-class in dxcolumn
</script>

<style lang="scss" scoped>
/***************
* # FILTRI
***************/
//
//.filterContainer { /* #globale */
//  padding: 10px;
//  display: flex;
//  justify-content: space-between;
//  flex-direction: row !important;
//  align-items: center;
//
//  //h2 {
//  //  font-family: Nunito, sans-serif;
//  //  font-size: 26px;
//  //  font-weight: 600;
//  //
//  //  @media screen and (max-width: 1024px){
//  //    font-size: 22px;
//  //  }
//  //}
//
//  //#categoriesListProv {
//  //  text-align: center;
//  //  padding: 7px;
//  //  border: none;
//  //  border-radius: 14px;
//  //  appearance: none;
//  //
//  //  color: #AA192F;
//  //  background-color: #FDE5E8;
//  //  //width: 117px;
//  //  //height: 24px;
//  //}
//
//}

/** ## CALENDAR **********/

/***************
* # BAR GAUGE
***************/
.gauge {
  //height: 232px;
  height: 300px;
}

/***************
* # SCATTER - DATAGRID
***************/
.scatterChart,
.datagrid {
  height: 413px;
}

.datagrid {
  //min-width: 1000px;
}

/***************
* # COMPONENT WORST PERFORMER - DATAGRID
***************/

/*** ***** #!! ATTENZIONE: alcuni elementi css non vengono presi xé il componente è scoped
 -> passarli in custom-style.css !! */

//.tenant-column {
//  color: orange !important;
//  font-size: 50px !important;
//  background-color: green !important;
//}
//
//
//.cell-highlighted {
//  background-color: green !important;
//  color: orange !important;
//  font-style: italic !important;
//  font-size: 25px !important;
//}

// boh? #check
.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 17px;
}

.vue-logo {
  width: 180px;
  height: 62px;
}

.plus {
  margin: 20px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}
// /boh? #check

@media screen and (max-width: 1024px) {
  .gauge {
    // #check: da mettere in custom-style? Pensaci dopo aver fatto tutte le pagine.
    //height: 175px;
    height: 240px;
  }

  .scatterChart,
  .datagrid {
    // #check: da mettere in custom-style? Pensaci dopo aver fatto tutte le pagine.
    height: 313px;
  }
}
@media screen and (max-width: 768px) {
}
</style>
