// vue crypto js

import axios from "axios";
import ApiCalls from "@/services/ApiCalls";
import router from "@/router";
import store from "@/store";
const { baseURLRoot } = require("../custom-config.js");

const defaultUser = !window.localStorage.loggedUser
  ? null
  : { email: JSON.parse(window.localStorage.getItem("loggedUser"))?.username };

const assets = window.localStorage.getItem("assets");
const defaultAsset = window.localStorage.getItem("defaultAsset");

if (assets) {
  defaultUser.assets = assets.split(",");
}

if (defaultAsset) {
  defaultUser.defaultAsset = defaultAsset;
}

//     {
//   // username: !window.localStorage.loggedUser ? 'null' : window.localStorage.getItem('loggedUser'),
//   email: !window.localStorage.loggedUser ? 'null' : window.localStorage.getItem('loggedUser').username,
//   // username: "oijoi",
//   // avatarUrl: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png'
// };

const q = require("querystring");

export default {
  _user: defaultUser,
  loggedIn() {
    console.debug("this_user logged in", this._user);
    return !!this._user;
  },

  async logIn(email, password) {
    return axios({
      method: "post",
      url: baseURLRoot + "/login",
      data: q.stringify({
        grant_type: "password",
        username: email,
        password: password,
        //username: 'Test Dashboard',
        //password: 'TestDashboard.01'
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => {
        window.localStorage.setItem("accessToken", res?.data?.access_token);
        window.localStorage.setItem("refreshToken", res?.data?.refresh_token);
        window.localStorage.setItem(
          "refreshTokenExpiringDate",
          res?.data?.[".expires"]
        );

        const assets = res?.data?.assets.split(",");
        let defaultAsset = res?.data?.defaultAsset;
        if (defaultAsset == "UNAVAILABLE") {
          //if the user has all assets (ex. 0,1,2) use the first non zero if default asset is UNAVAILABLE
          if (assets.length > 1 && assets[0] == 0) {
            defaultAsset = assets[1];
          } else {
            defaultAsset = assets[0];
          }
        }

        window.localStorage.setItem("defaultAsset", defaultAsset);
        window.localStorage.setItem("assets", assets);

        const loggedUser = { username: email, assets, defaultAsset };

        window.localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
        this._user = { ...defaultUser, email, assets, defaultAsset };
        console.debug("_user: ", this._user);

        return {
          isOk: true,
          data: this._user,
        };
      })
      .catch((err) => {
        console.log("axios err: ", err.response.data.error);

        return {
          isOk: false,
          data: err?.response?.data?.error,
        };
      });
  },

  async logOut() {
    try {
      await ApiCalls.delLogOut();
    } catch (err) {
      console.log("ALW Logout Error ", err.response.data.error);
    }

    window.localStorage.clear();
    store.state.selectedOutlet = null;
    this._user = null;
    router.push({
      path: "/login-form",
      query: { redirect: router.path },
    });
  },

  async getUser() {
    try {
      return {
        isOk: true,
        data: this._user,
      };
    } catch {
      return {
        isOk: false,
      };
    }
  },

  async resetPassword(email) {
    try {
      // Send request
      console.log(email);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to reset password",
      };
    }
  },

  async changePassword(email, recoveryCode) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to change password",
      };
    }
  },

  async createAccount(email, password) {
    try {
      // Send request
      console.log(email, password);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to create account",
      };
    }
  },
};
