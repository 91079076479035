import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import Vue from "vue";

import App from "./App";
import router from "./router";
import appInfo from "./app-info";
import store from "./store";

// My components
import BasicDataVisualizer from "@/components/basic-data-visualizer.vue";
import BasicDataVisualizerSmall from "@/components/basic-data-visualizer-small.vue";
import LineChartDataVisualizer from "@/components/lineChart-data-visualizer/lineChart-container.vue";
import DoughnutDataVisualizer from "@/components/doughnut-visualizer/doughnut-data-visualizer.vue";
import Placeholder from "@/components/placeholder.vue";
import StackedChartDatagridVisualizer from "@/components/stacked-chart-datagrid-visualizer.vue";
import BasicChartDatagridVisualizer from "@/components/basic-chart-datagrid-visualizer.vue";
import BasicDatagridVisualizer from "@/components/basic-datagrid-visualizer.vue";
import BasicDoubleDoughnutVisualizer from "@/components/basic-double-doughnut-visualizer.vue";
import TempChartVisualizer from "@/components/temp-chart-visualizer.vue";
import TempChartVisualizerLink from "@/components/temp-chart-visualizer_link.vue";
import TempDatagridVisualizer from "@/components/temp-datagrid-visualizer.vue";
import BasicTextVisualizer from "@/components/basic-text-visualizer.vue";
import AdvancedTextVisualizer from "@/components/advanced-text-visualizer.vue";
import BasicChartVisualizer from "@/components/basic-chart-visualizer.vue";
import AdvancedDatagridVisualizer from "@/components/advanced-datagrid-visualizer.vue";
import CustomBarGaugeLegendTemplate from "@/components/doughnut-visualizer/partials/custom-bar-gauge-legend-template.vue";
import LinkCellTemplate from "@/components/datagrid/LinkCellTemplate";
import RollingCellTemplate from "@/components/datagrid/RollingCellTemplate";
import CustomCalendarCellTemplate from "./components/calendar/custom-calendar-cell-template";
import apiCalls from "./services/ApiCalls";

Vue.component("BasicDataVisualizer", BasicDataVisualizer);
Vue.component("BasicDataVisualizerSmall", BasicDataVisualizerSmall);
Vue.component("DoughnutDataVisualizer", DoughnutDataVisualizer);
Vue.component("LineChartDataVisualizer", LineChartDataVisualizer);
Vue.component("Placeholder", Placeholder);
Vue.component("BasicChartDatagridVisualizer", BasicChartDatagridVisualizer);
Vue.component("StackedChartDatagridVisualizer", StackedChartDatagridVisualizer);
Vue.component("BasicDatagridVisualizer", BasicDatagridVisualizer);
Vue.component("BasicDoubleDoughnutVisualizer", BasicDoubleDoughnutVisualizer);
Vue.component("TempChartVisualizer", TempChartVisualizer);
Vue.component("TempChartVisualizerLink", TempChartVisualizerLink);
Vue.component("TempDatagridVisualizer", TempDatagridVisualizer);
Vue.component("BasicTextVisualizer", BasicTextVisualizer);
Vue.component("AdvancedTextVisualizer", AdvancedTextVisualizer);
Vue.component("BasicChartVisualizer", BasicChartVisualizer);
Vue.component("AdvancedDatagridVisualizer", AdvancedDatagridVisualizer);
Vue.component("CustomBarGaugeLegendTemplate", CustomBarGaugeLegendTemplate);
Vue.component("LinkCellTemplate", LinkCellTemplate);
Vue.component("RollingCellTemplate", RollingCellTemplate);
Vue.component("CustomCalendarCellTemplate", CustomCalendarCellTemplate);

Vue.mixin({
  data() {
    return {
      expiryDateValue: 86400000, // un giorno in millisecondi
      isLocalExpired: null, // boolean: controlla se i dati nel localStorage sono più vecchi di expiryDateValue
      isLocalStorageUsed: null, // flag per abilitare salvataggio di dati nel local storage
      lastUpdateUTCTimestamp: null, // timestamp arrivato da chiamata
    };
  },
  methods: {
    //  formattazione corretta dati
    displayCorrectFormat({ value, isNumber = false, isDate = false }) {
      // è null?
      if (value == null) {
        return "-";
      }

      // è un numero?
      if (isNumber) {
        return value.toLocaleString("it-IT");
      }

      // è una data?
      if (isDate) {
        return `${value.slice(6, 8)}/${value.slice(4, 6)}/${value.slice(0, 4)}`;
      }

      return value;
    },

    //  cancella i valori dal local storage (dati outlets e utente)
    deleteLocalStorageItems() {
      window.localStorage.clear();
      console.debug("local storage deleted: ", window.localStorage);
    },

    // imposta i valori nel local storage
    setLocalStorageItem(key, value) {
      let result;
      typeof value == "object"
        ? (result = JSON.stringify(value))
        : (result = value);
      window.localStorage.setItem(key, result);
      console.debug(`setLocalStorage on ${key}: `, window.localStorage[key]);
    },

    // recupera i valori dal local storage
    retrieveLocalStorageItem(key) {
      console.debug(
        `retrieveLocalStorage called on ${key}: `,
        JSON.parse(window.localStorage.getItem(key))
      );
      return JSON.parse(window.localStorage.getItem(key));
    },

    // imposto data creazione dati local storage (basato su lista outlet)
    setLocalStorageItemCreatedAt() {
      window.localStorage.setItem("outletsListCreatedAt", new Date());
      console.debug(
        `local storage created at: `,
        window.localStorage.outletsListCreatedAt
      );
    },

    // imposto data ultimo update dati ricevuti: termine di paragone per decidere se local storage è vecchio
    setLocalStoragelastUpdateUTCTimestamp(value) {
      window.localStorage.setItem("lastUpdateUTCTimestamp", value);
      console.debug(
        `local storage lastUpdateUTCTimestamp: `,
        window.localStorage.lastUpdateUTCTimestamp
      );
    },

    // metodi raccolta dati componenti
    /** ## FILTRO OUTLETS - parte 2: definizione chiamate api necessarie **********/
    getDataByCategories() {
      // recupero le categorie dell'outlet e le imposto nel vuex

      console.debug(`getDataByCategories called, ls: `, window.localStorage);

      if (
        !window.localStorage[
          "productCategoriesListByOutlet" + this.$store.state.selectedOutlet.id
        ]
      ) {
        if (this.$store.state.selectedOutlet.id !== 0) {
          apiCalls
            .getCategories(this.$store.state.selectedOutlet.id)
            .then((res) => {
              console.debug(`prod cat api called`, res);
              const DATA = res.data;

              // salvo in vuex le categorie
              this.$store.dispatch("dispatchCategoriesList", DATA);
              //   console.log("0.3 categories list - store: ", this.$store.state);

              // salvo nel window local storage le categorie merceologiche
              // if(!this.isLocalStorageUsed) {
              this.setLocalStorageItem(
                "productCategoriesListByOutlet" +
                  this.$store.state.selectedOutlet.id,
                DATA
              );
              console.debug(`prod cat created ls: `, window.localStorage);
              // }

              /***************
               * # CHIAMATA 1: locale, getters per popolare liste filtri (outlets, dates, categorie da vuex)
               ***************/
              // console.log("dates store getter: ", this.getOutletLastSalesDates);

              /***************
               * # CHIAMATA 2: api, fetch dati pagina er e valorizzazione data-source dei vari componenti -> #fn api in custom-file.js
               ***************/
              this.getEffortRateData();
            })
            .catch((err) => console.log(err.response));
        } else {
          /***************
           * # CHIAMATA 2: api, fetch dati pagina er e valorizzazione data-source dei vari componenti -> #fn api in custom-file.js
           ***************/
          this.getEffortRateData();
        }
      } else {
        console.debug(`prod cat loc storage called`);

        const DATA = this.retrieveLocalStorageItem(
          "productCategoriesListByOutlet" + this.$store.state.selectedOutlet.id
        );

        // salvo in vuex le categorie
        this.$store.dispatch("dispatchCategoriesList", DATA);
        console.log("0.3 categories list - store: ", this.$store.state);

        // salvo nel window local storage le categorie merceologiche
        // if(!this.isLocalStorageUsed) {
        //   this.setLocalStorageItem('productCategoriesList', DATA)
        // }

        /***************
         * # CHIAMATA 1: locale, getters per popolare liste filtri (outlets, dates, categorie da vuex)
         ***************/
        // console.log("dates store getter: ", this.getOutletLastSalesDates);

        /***************
         * # CHIAMATA 2: api, fetch dati pagina er e valorizzazione data-source dei vari componenti -> #fn api in custom-file.js
         ***************/
        this.getEffortRateData();
      }
    },

    getOutletsData() {
      // recupero vesione app
      apiCalls.getAppVersion().then((res) => {
        // window.localStorage.appVersion = JSON.stringify(res.data); // todo: sarebbe meglio salvarlo in vuex?
        // console.debug("app version: ", window.localStorage.appVersion);

        console.debug("prova version: ", res.data);

        this.$store.dispatch("dispatchAppVersion", res.data);

        // recupero timestamp last update
        apiCalls
          .getLastUpdateUTCTimestamp()
          .then((res) => {
            console.debug("call dopo version: ");
            this.lastUpdateUTCTimestamp = res.data.lastUpdateUTCTimestamp;
            console.debug(
              "lastUpdateUTCTimestamp da chiamata: ",
              this.lastUpdateUTCTimestamp
            );

            // check se local storage è aggiornato
            this.isLocalExpired = window.localStorage.lastUpdateUTCTimestamp
              ? window.localStorage.lastUpdateUTCTimestamp !=
                this.lastUpdateUTCTimestamp
              : true;
            console.debug("isLocalExpired:", this.isLocalExpired);

            // check se localstorage contiene già i dati necessari e se sono aggiornati
            let isLocalOutletsList = window.localStorage.outletsList
              ? true
              : false; // controllo se voce nel local storage esiste
            console.debug(
              `isLocalOutletsList:${isLocalOutletsList}; isLocalExpired:${this.isLocalExpired}`
            );

            // i dati nel localStorage non esistono o sono troppo vecchi
            if (isLocalOutletsList == false || this.isLocalExpired == true) {
              this.isLocalStorageUsed = false; // #fn globale

              /** ## outlets **********/
              apiCalls.getOutlets().then((res) => {
                console.debug(`ApiCalls.getOutlets called`);
                const DATA = res.data;

                // salvo in vuex gli outlets
                this.$store.dispatch("dispatchOutletsList", DATA);
                // console.log("0.1. outlets list - store: ", this.$store.state);

                // salvo nel window local storage gli outlets
                if (!this.isLocalStorageUsed) {
                  this.setLocalStorageItem("outletsList", DATA);
                  this.setLocalStorageItemCreatedAt();
                  this.setLocalStoragelastUpdateUTCTimestamp(
                    this.lastUpdateUTCTimestamp
                  );
                  this.isLocalStorageUsed = true;
                }

                // imposto il selectedOutlet di default in vuex, se selectedOutlet non è già impostato
                if (this.$store.state.selectedOutlet == null) {
                  let defaultOutlet = DATA.filter(
                    (outlet) => outlet.id === 1
                  )[0];
                  this.$store.dispatch("dispatchSelectedOutlet", defaultOutlet);
                  // console.log("(0.1.2). defaultOutlet: ", defaultOutlet);
                }

                /** ## date **********/
                // prendo l'outlet selezionato da store e lo uso per ricavare e impostare in vuex le date delle ultime fatturazioni
                let dates = DATA.filter(
                  (outlet) => outlet.id === this.$store.state.selectedOutlet.id
                )[0]; // old

                // #n
                console.log("dates nuovo: ", dates);

                let lastAvailableSalesDates = [
                  dates.lastAvailableMonthLTM,
                  dates.lastAvailableMonthREF,
                  dates.lastAvailableMonthYTD,
                ];
                let firstAvailableSalesDates = [
                  dates.firstAvailableMonthLTM,
                  dates.firstAvailableMonthREF,
                  dates.firstAvailableMonthYTD,
                ];

                let lastAvailableSalesDate = Math.min(
                  ...lastAvailableSalesDates
                );
                console.log("last sales date: ", lastAvailableSalesDate);
                let firstAvailableSalesDate = Math.max(
                  ...firstAvailableSalesDates
                );
                console.log("first sales date: ", firstAvailableSalesDate);

                let newDates = {
                  ...dates,
                  firstAvailableSalesDate: firstAvailableSalesDate,
                  lastAvailableSalesDate: lastAvailableSalesDate,
                };

                this.$store.dispatch(
                  "dispatchNewOutletLastSalesDates",
                  newDates
                );
                console.log(
                  "new 0.2. outlet last sales dates - store: ",
                  this.$store.state.newOutletLastSalesDates
                );
                // /#n

                this.$store.dispatch("dispatchOutletLastSalesDates", dates); // old
                console.log(
                  "0.2. outlet last sales dates - store: ",
                  this.$store.state.outletLastSalesDates
                );

                // imposto ultima data selezionabile
                this.setMaxSelectableDate();

                /** ## categorie **********/
                this.getDataByCategories();
              });
            } else {
              this.isLocalStorageUsed = true;
              console.debug(`local storage data used`);

              const DATA = this.retrieveLocalStorageItem("outletsList");

              // salvo in vuex gli outlets
              this.$store.dispatch("dispatchOutletsList", DATA);
              // console.log("0.1. outlets list - store: ", this.$store.state);

              // salvo nel window local storage gli outlets
              if (!this.isLocalStorageUsed) {
                this.setLocalStorageItem("outletsList", DATA);
                this.setLocalStorageItemCreatedAt();
                this.setLocalStoragelastUpdateUTCTimestamp(
                  this.lastUpdateUTCTimestamp
                );
              }

              // imposto il selectedOutlet di default in vuex, se selectedOutlet non è già impostato
              if (this.$store.state.selectedOutlet == null) {
                let defaultOutlet = DATA.filter((outlet) => outlet.id === 1)[0];
                this.$store.dispatch("dispatchSelectedOutlet", defaultOutlet);
                // console.log("(0.1.2). defaultOutlet: ", defaultOutlet);
              }

              /** ## date **********/
              // prendo l'outlet selezionato da store e lo uso per ricavare e impostare in vuex le date delle ultime fatturazioni
              let dates = DATA.filter(
                (outlet) => outlet.id === this.$store.state.selectedOutlet.id
              )[0]; // old

              // #n
              console.log("dates nuovo: ", dates);

              let lastAvailableSalesDates = [
                dates.lastAvailableMonthLTM,
                dates.lastAvailableMonthREF,
                dates.lastAvailableMonthYTD,
              ];
              let firstAvailableSalesDates = [
                dates.firstAvailableMonthLTM,
                dates.firstAvailableMonthREF,
                dates.firstAvailableMonthYTD,
              ];

              let lastAvailableSalesDate = Math.min(...lastAvailableSalesDates);
              console.log("last sales date: ", lastAvailableSalesDate);
              let firstAvailableSalesDate = Math.max(
                ...firstAvailableSalesDates
              );
              console.log("first sales date: ", firstAvailableSalesDate);

              let newDates = {
                ...dates,
                firstAvailableSalesDate: firstAvailableSalesDate,
                lastAvailableSalesDate: lastAvailableSalesDate,
              };

              this.$store.dispatch("dispatchNewOutletLastSalesDates", newDates);
              console.log(
                "new 0.2. outlet last sales dates - store: ",
                this.$store.state.newOutletLastSalesDates
              );
              // /#n

              this.$store.dispatch("dispatchOutletLastSalesDates", dates); // old
              console.log(
                "0.2. outlet last sales dates - store: ",
                this.$store.state.outletLastSalesDates
              );

              // imposto ultima data selezionabile
              this.setMaxSelectableDate();

              /** ## categorie **********/
              this.getDataByCategories();
            }
          })
          .catch((err) => console.error(err));
      });
    },
  },
  created() {
    // this.isLocalExpired = window.localStorage.outletsListCreatedAt ? (new Date - window.localStorage.outletsListCreatedAt) > this.expiryDateValue : true;
    // promemoria: convertire data in formato obj js
    // const result = new Date("20220126T153503".replace(
    //     /(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})/,
    //     "$1-$2-$3T$4:$5:$6"
    // ));
  },
});

Vue.config.productionTip = false;
Vue.prototype.$appInfo = appInfo;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
